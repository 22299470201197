.testimonials-section {
    padding: 4rem 0;
    background-color: rgb(250, 238, 232);
  }
  
  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .testimonial-card {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    width: calc(50% - 1rem);
    max-width: 500px;
    border: 1px solid rgb(250, 198, 172);
    transition: transform 0.1s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    transform-style: preserve-3d;
    will-change: transform;
  }
  
  .testimonial-content {
    position: relative;
    z-index: 1;
  }
  
  .testimonial-card:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    border-color: rgb(250, 178, 142);
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: black;
  }
  
  .testimonial-author {
    font-weight: bold;
    text-align: right;
    color: black;
  }
  
  @media (max-width: 768px) {
    .testimonial-card {
      width: 100%;
    }
  }
  
  
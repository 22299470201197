@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');


:root {
    --background:#353535 ;
    --primary: #e0fbfc;
    --secondary: #284b63;
    font-family: 'Roboto', sans-serif !important;

  }


  #root{
    background: rgb(250, 238, 232) !important;
    padding: 0;
    overflow: hidden !important;
    margin: 0;
  }






body{
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden !important;
    background: rgb(251,237,230);
    font-family: 'Roboto', sans-serif !important;
}

p{
  color: black !important;
}

.height{
  height: 88vh;
}



  .header-background{
    z-index: 999;
    height: 12vh;
    width: 100vw;
    /* position: fixed; */
    padding: 0 !important;
    margin: 0 !important;
    background: rgba(0, 0, 0, 0.205);
  }

  .img-height{
    margin-left: 30px !important;
    margin-right: 15px;
    height: 65px !important;
  }



  .owner-header-width{
    width: 80%;
  }

  .owner-sub-width{
    width: 480px;
  }


  .owner-text-size{
    font-size: 18px;
  }

  .owner-text-name{
    font-size: 30px ;
  }

  .owner-img-size{
    height: auto;
    max-height: 350px;
    max-width: 90%;
  }

  .owner-title{
    font-size: 26px;
  }

  .owner-sub-wid{
    width: 400px;
  }
  

  .we-title{
    font-size: 20px;
  }

  .owner-col-width{
    width: 65%;
  }













  .remi-profile {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-image {
    text-align: center;
    margin: 20px 0;
  }
  
  .profile-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .profile-content {
    background: rgb(252, 227, 215);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  @media (max-width: 600px) {
    .profile-content {
      padding: 15px;
    }
  }
  






















  .logo-name{
    color: white !important;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
    font-size: 13px;
    margin: 0 !important;
  }

  .td-none{
    text-decoration: none !important;
  }
  
  .nav-link{
    
    position: relative;
    color: white !important;
    font-size: 13px !important;
    margin-right: 60px !important;
    margin-bottom: 0 !important;
    transition: .5s;

    background-color: none;
  }

  .font-size{
    font-size: 13px !important;
  }

  .font-size-mobile{
    font-size: 13px !important;
    color: black !important;
  }


  .bg-none{
    background: none !important;
    border: none !important;
  }

  .remis-intro-w{
    width: 30%;
  }

  .remis-header-img{
    height: 400px;
  }

  .remis-story-p{
    width: 50%;
    font-size: 14px;
  }


  .hey-there{
    font-size: 27px;
  }


  .nav-link:after {
    position: absolute;
    content: '';
    height: 2px;
      /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
    bottom: -4px; 


   /****** 
   optional values below 
   ******/
    
    /* center - (optional) use with adjusting width   */
    margin: 0 auto;
      left: 0;
    right: 0;
    /* must be the same as nav-link padding-right otherwise it will not align */
      width: 0%;
      background: rgb(250, 198, 172) ;
      
      /* optional animation */
      -o-transition:.5s;
        -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}

.nav-link:hover{
    cursor: pointer;
    color: white !important;
}
  .nav-link:hover:after{
    width: 70%;
    background: rgb(250, 198, 172)  !important;
    cursor: pointer;
  } 

  .far-right{
    margin-right: 30px !important;
  }

  .contact-me{
    font-size: 15px !important;
    margin-bottom: 0 !important;
    border: 1px white solid;
    border-radius: 10px;
    padding-right: 16px;
    padding-left: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: .5s;
    color: white !important;
  }
  .contact-me:hover{
    background: rgb(250, 198, 172);
    box-shadow: 0 0 15px rgb(250, 198, 172);
    border:1px rgb(250, 198, 172) solid;
    color: white !important;
    cursor: pointer;
  }
  
  .home{
    background-image: linear-gradient(rgba(70, 69, 69, 0.5), rgba(2, 2, 2, 0.726)), url("../images/newbg.jpg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    margin: 0 !important;
    padding-right: 0 !important;
    height:100vh !important;  /* responsive height */
  }




  .remis-story-bg{

    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    margin: 0 !important;
    padding-right: 0 !important;
    height:100vh !important;  /* responsive height */
  }





  
.pb{

  background-image: linear-gradient(rgba(70, 69, 69, 0.5), rgba(2, 2, 2, 0.295)), url("../images/remis1.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center top 40%; 
    background-size: cover !important;
    padding-right: 0 !important;
    margin: 0 !important;
    width: 100vw !important;
    height:100vh !important;  /* responsive height */
}


.ob{

  background-image: linear-gradient(rgba(70, 69, 69, 0.5), rgba(2, 2, 2, 0.295)), url("../images/new_ob_img (1).jpg") !important;
  background-repeat: no-repeat !important;
  background-position: top  !important; /* Adjust the value as needed */
  background-size: cover !important;
  padding-right: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;  /* responsive height */
}


.wedding{
  background-image: linear-gradient(rgba(48, 47, 47, 0.603), rgba(0, 0, 0, 0.842)), url("../images/weddingbg-2.jpg") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  padding-right: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
  height: 100vh !important;  /* responsive height */
}


.contact{
  padding-right: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
  /* height: 100vh !important;  responsive height */
}

.etsy{
  background: rgb(250, 238, 232);
  padding-right: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
}








.dog-graduation-page {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: center;
}

.dog-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.dog-card {
  flex-basis: calc(30% - 10px); /* Adjust for gap between cards */
  border: 1px solid #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: rgb(252, 227, 215);
  transition: transform 0.2s ease-in-out;
  text-align: center;
}

.dog-card:hover {
  transform: translateY(-5px);
}

h2 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
}

.dog-image {
  width: 140px;
  height: 140px;
  border-radius: 8px;
  margin: 0 auto 10px;
  display: block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

p {
  margin: 10px 0;
  color: #777;
}

.additional-info {
  font-style: italic;
  color: #888;
}

@media (max-width: 800px) {
  .dog-card-container {
    justify-content: center;

  }
  .dog-graduation-page{
    width: 100%;
  }

  .dog-card{
    flex-basis: calc(50% - 10px);
  }
}

@media (max-width: 500px){
  .dog-card{
    flex-basis: calc(95%);
  }
}












.owner-portfolio {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.owner-portfolio h2 {
  margin-top: 20px;
  color: #333;
  font-size: 24px;
}

.owner-portfolio p {
  margin-top: 10px;
  color: #777;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .owner-portfolio {
    padding: 10px;
  }
}



































.etsy-img{
  height: 200px;
  width: 200px;
  object-fit: cover;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}

.etsy-header{
  color: black !important;
  text-align: center;
}

.etsy-margin-top{
  padding-top: 90px;
}

.etsy-main-width{
  max-width: 90%;
  flex-wrap: wrap;
}

.etsy-p{
  color: black !important;
  width: 300px;
  font-size: 13px;
  text-align: center;
  margin-left: auto !important;
  margin-right: auto !important;

}

.etsy-p-h{
  height: 48px;
}

.etsy-width{
  width: 300px;
}


.etsy-title{
  font-size: 35px;
}

.etsy-btn{
  border: rgb(250, 198, 172) 1px solid;
  text-align: center;
  color: black !important;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 200px;
  margin-right: auto !important;
  margin-left: auto !important;
  transition: .5s;
}

.etsy-btn:hover{
  cursor: pointer;
  background: rgb(250, 198, 172);
  color: black !important;
}

.gallery-title{
  font-size: 35px;
}

.gallery-img{
  height: 270px;
  width: 200px;

}

.mt-gal{
  margin-top: 0 !important;
}

.gallery-width{
  width: 100%;
  height: 100%;
}

.gallery-img-sp{
  height: 270px;
  width: 220px;
}

.grad-width{
  max-width: 90%;
}

.white{
  background: white !important;
}

.pink{
  background: rgb(250, 238, 232) !important;
}


.align-left{
  text-align: left;
  margin-left: 20px;
}



.padding-margin-0{
  padding: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
}

.pb-container-w{
  width: 70%;
}


.gallery{
  background: rgb(250, 238, 232);
  padding-right: 0 !important;
  margin: 0 !important;
  width: 100vw !important;
}


.gal-p{
  width: 200px;
}



.contact-form{
  margin-top: 65px !important;
  border-radius: 16px;
}







  .basics{
    margin: 0 !important;
    padding-right: 0 !important;
    width: 100vw !important;
    height:100vh !important;  /* responsive height */
  }


  .dog-img-height{
    height: 300px;
  }

  .home-text-width{
    width: 600px;
    color: white !important;
    font-size: 15px;
  }


  .pb-basics-text-width{
    width: 800px;

    color: white !important;
    font-size: 15px;
  }



  .carousel-h1{
    color: black !important;
    font-size: 30px;
    font-weight: 800px;
    margin-bottom: 0 !important;
  }

  .home-h1{
    color: white !important;
    font-size: 45px;
    margin-bottom: 30px;
  }

  .contact-h1{
    color: black !important;
    font-size: 30px;
    margin-bottom: 30px;
  }

  .pb-h1{
    color: white !important;
    font-size: 50px;
    margin-bottom: 30px;

  }

  .homeCarousel{
    background: white;
    padding: 0;
    margin: 0;
    width: 100vw;

    background-size:     cover;                      /* <------ */
    background-repeat:   no-repeat;
    background-position: center center;  
    font-family: 'Roboto', sans-serif;

  }


  .home-cta{
    border: 1px rgb(250, 178, 142) solid; 
    color: white !important;
    padding: 7px 10px;
    border-radius: 16px;
    font-size: 18px;
    transition: .5s;
  }

  .home-cta:hover{
    background: rgb(250, 198, 172);
    border: 1px rgb(250, 198, 172) solid;
    color: white !important;
    cursor: pointer;
    box-shadow: 0 0 15px rgb(255, 177, 139);
    letter-spacing: .1em;
  }





  .img{
    width: 300px !important;
    height: 350px !important;
  }

  .img-spc{
    transition: .5s;
    width: 300px !important;
    padding-top: 55px;
    padding-bottom: 55px;
    height: auto !important;
  }



  .info-cta{
    height: 300px;
    width: 375px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border: 1px rgb(250, 198, 172) solid;
    box-shadow: 20px rgb(250, 198, 172);
    border-radius: 16px;
    text-align: center;
  }

  .mt-auto{
    
  }

  .pb-text{
    height: 300px;
    width: 375px !important;
  }

  .info-cta-wc{
    height: 350px;
    width: 375px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ccc;
    box-shadow: 20px rgb(250, 198, 172);
    border-radius: 16px;
    text-align: center;
  }

  .wc-text-width{
    width: 80%;
  }

.wc-div-size{
  width:60%;
}
















.product-features {
  text-align: center;
  padding: 20px;
}

.feature-cards {
  display: grid;
  justify-content: center !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.feature-card {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.feature-icon {
  font-size: 24px;
  margin-bottom: 10px;
}

.feature-title {
  margin-top: 0;
}

.feature-description {
  color: #555;
}
















.align-ul-center{
  text-align: center !important;

}

  .ob-info-cta{
    text-align: center;
    width: 50vw;
  }

  .center{
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }




  .info-header{
    color: black !important;
    font-size: 20px; 
  }


  .carousel-cta{
    border: 1px rgb(250, 178, 142) solid; 
    color: black !important;
    margin-top: auto !important;
    padding: 7px 10px;
    border-radius: 16px;
    text-align: center;
    width: 170px;
    font-size: 18px;
    transition: .5s;
  }











  .contact-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .service-areas {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }
  
  .service-areas li {
    margin: 5px;
    padding: 5px 10px;
    background-color: rgb(250, 198, 172);
    border-radius: 5px;
  }
  











  
  .carousel-cta:hover{
    background: rgb(250, 198, 172);
    border: 1px rgb(250, 198, 172) solid;
    color: white !important;
    cursor: pointer;
    box-shadow: 0 0 15px rgb(255, 177, 139);
    letter-spacing: .1em;
  }

  .underline{
    position: relative;
  }


  .underline:after {
    position: absolute;
    content: '';
    height: 2px;
  /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
    bottom: -4px; 


   /****** 
   optional values below 
   ******/
    
    /* center - (optional) use with adjusting width   */
    margin: 0 auto;
    padding: 0 !important;
    left: 0px;
    right: 0;
    width: 100px;
    background: rgb(250, 198, 172);
  
  /* optional animation */
  -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition:.5s;
}




.underline-r{
  position: relative;
}


.underline-r:after {
  position: absolute;
  content: '';
  height: 2px;
/* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
  bottom: -4px; 


 /****** 
 optional values below 
 ******/
  
  /* center - (optional) use with adjusting width   */
  margin: 0 auto;
  padding: 0 !important;
  left: 0px;
  right: 0;
  width: 75px;
  background: white;

/* optional animation */
-o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}

.pb-font-size-header2{
  font-size: 25px;
}








.pb-underline{
  position: relative;
}


.pb-underline:after {
  position: absolute;
  content: '';
  height: 2px;
/* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
  bottom: -4px; 


 /****** 
 optional values below 
 ******/
  
  /* center - (optional) use with adjusting width   */
  margin: 0 auto;
  padding: 0 !important;
  left: 0px;
  right: 0;
  width: 200px;
  background: rgb(250, 198, 172);

/* optional animation */
-o-transition:.5s;
  -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}









.info-cta-obedience{
  height: 350px;
  width: 300px;
  font-size: 20px; 
  border: 1px rgb(250, 198, 172) solid;
  box-shadow: 20px rgb(250, 198, 172);
  border-radius: 16px;
  text-align: center;
}


.info-text{
  font-size: 16px ;
}
  

.footer{
  height: 150px;
  width: 100vw !important;
  background: rgba(250, 198, 172, 0.582);
}

.footer-img{
  height: 50px;
  width: 50px;
  margin: 0 25px;
}

.font-fm{
  font-family: 'Roboto', sans-serif;
}


.marging-right{
  margin-right: 10px !important;

}


.input-field{
  display: block;
  width: 300px;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 5px;
  border: none;
}

.input-field-l{
  display: block;
  width: 80%;
  font-size: 14px;
  resize: none;
  border-radius: 4px;
  padding-left: 5px;
  border: none;
}

.submit-btn{
  border: none;
  border-radius: 16px;
  background: rgb(250, 198, 172);
  padding: 5px 30px;
  font-size: 20px;
  transition: .5s;
}

.submit-btn:hover{

    color: white !important;
    cursor: pointer;
    box-shadow: 0 0 15px rgb(255, 177, 139);
    letter-spacing: .1em;

}




.footer-img-fb{
  height: 45px;
  width: 45px;
  margin: 0 25px;
}

.remis-height{
  margin-bottom: 120px;
  background: white
}


.story-cta{
  border:  1px white solid;
  padding: 8px 15px;
  border-radius: 10px;
  transition: .5s;
}

.story-cta:hover{
  background: white;
  box-shadow: 0 0 15px white;
  color: black !important;
  cursor: pointer;
}



.remis-text-border{
  background: rgba(250, 198, 172, 0.555);
  color: black !important;
  border-radius: 10px;
  height: auto;
  max-width: 90% !important;
  margin-top: 20px !important;
  padding:  40px 0px 10px 10px !important;
}


.remis-text{
  font-size: 17px;
}

.remis-cta{
  width: 200px;
}

.remis-container{
  width: 80% !important;
}

.menu-height{
  height: 40px;
  margin-right: 30px;
  margin-left: 10px;
}

.nav-link-mobile{
color: black !important;
}



.nav-link-mobile:hover{
  cursor: pointer;
}

.nav-link-mobile:hover:after{
  width: 50%;
  background: rgb(250, 198, 172)  !important;
  cursor: pointer;
} 


.nav-link-mobile:after {
  position: absolute !important;
  content: '';
  height: 2px;
    /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
  bottom: -4px; 


 /****** 
 optional values below 
 ******/
  
  /* center - (optional) use with adjusting width   */
  margin: 0;
    left: 0;
  right: 0;
  /* must be the same as nav-link padding-right otherwise it will not align */
    width: 0%;
    background: rgb(250, 198, 172)  !important;
    
    /* optional animation */
    -o-transition:.5s;
      -ms-transition:.5s;
  -moz-transition:.5s;
  -webkit-transition:.5s;
  transition:.5s;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 35px;
  height: 22px;
  right: 28px;
  top: 23px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0; 
  /* added this line of code here potential break for animations!! */
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}







@media screen and (max-width: 900px) {
  .etsy-main-width{
    width: 70%;
  }
}

@media  screen and (max-width: 1000px) {
  .mt03{
    margin-top: 3rem !important;
  }
}

@media screen and (max-width: 857px) {
  .mt04{
    margin-top: 3rem !important;
  }
}


@media screen and (max-width: 650px) {
  .home-text-width{
    width: 450px;
    font-size: 13px;
  }
  .home-h1{
    font-size: 35px;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 510px) {
.home-text-width{
  width: 325px;
  font-size: 14px !important;
}
.home-h1{
  font-size: 25px;
  margin-bottom: 30px;
}

.home-cta{
  font-size: 15px;
}
}

@media screen and (max-width: 991px){
  .pb-img-height{
    height: 250px !important;
    width: 300px !important;
    border-radius: 16px !important;
  }

  .pb-text-width{
    width: 90vw !important;
  }
  .ob-info-cta{
    width: 100vw;
  }
}



@media screen and (max-width: 1120px) {
  .info-cta{
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 440px) {
  .info-cta{
    margin-top: 30px !important;
  }
  .remis-height{
    margin-bottom: 75px;
  }

  .remis-text-border{
    height: auto;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 485px) {
  .remis-container{
    width: 80% !important;
  }
}



@media screen and (max-width: 440px) {
  .img-height{
    margin-left: 15px !important;
    margin-right: 15px;
    height: 65px !important;
  }
}

.subheading-mobile{
  font-size: 14px;
}

.basics-text-width{
  width: 800px;
    color: white !important;
    font-size: 15px;
}




.nav-link-mobile{
  color: black;
  font-size: 18px  !important;
  position: relative !important;
  transition: .5s;
  padding: 0 !important;
  margin: 15px 0px !important;
}

.p-none{
  font-size: 18px !important;
  padding: 0 !important;
  color: white;
}


.dropdown-bg{
  background: rgba(61, 61, 61, 0.808) !important ;
}

.dropdown-color{
  color: white !important;
}


.dropdown-color:hover{
  color: black !important;
}

.dropdown-color:active{
  color: black !important;
  background: white !important ;
}






.dog-training-services {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.service{
  background-color: rgb(250, 238, 232) !important;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;
}





.puppy-basics-package {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service {
  flex-basis: calc(40% - 20px);
  background-color: rgb(250, 198, 172);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;
}

.service img {
  max-width: 200px !important;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.service h2 {
  color: #333;
  font-size: 24px;
}

.service p {
  color: #777;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .service {
    flex-basis: 100%;
    padding: 15px;
  }
}















.service img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.service h2 {
  color: #333;
  font-size: 24px;
}

.service p {
  color: #777;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .service {
    padding: 15px;
  }
}

















.pb-img-height{
  max-height: 400px;
  height: auto;
  width: auto;
  border-radius: 8px !important;
}

.pb-text-width{
  width: auto;
  max-height: 500px ;
}
.container-ob{
  width: 90%;
}

.pb-text-width-2{
  width: 90%;
}


.pb-font-size-header{
  font-size: 20px;
}

.pb-font-size{
  font-size: 16px;
}




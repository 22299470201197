.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }
  
  .header {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
    color: #333; /* Darker text for contrast */
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .list-item {
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .list-item:hover {
    background-color: #f0dfd9; /* Slightly darker shade for hover effect */
  }
  
  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .title {
    font-size: 1.5em;
    color: #333; /* Darker text for contrast */
    margin-bottom: 10px;
  }
  
  .arrow {
    font-size: 1.5em;
    color: #333; /* Darker text for contrast */
    transition: transform 0.3s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg); /* Rotates the arrow when the description is open */
  }
  
  .description {
    font-size: 1em;
    color: #555;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    max-height: 0;
  }
  
  .description.active {
    opacity: 1;
    max-height: 100px;
  }
  
  .list-item {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
  }
  
  .list-item.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  